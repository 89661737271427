export default {
	install: function (Vue) {

		const helpers = {
			/**
			 * Return if is mobile
			 * @returns {boolean}
			 */
			isMobile () {
				return window.innerWidth < 600
			},
			/**
			 * Return a nested object
			 * @param object
			 * @param path
			 * @returns {*}
			 */
			getObjectByStringPath (object, path) {
				path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
				path = path.replace(/^\./, '');           // strip a leading dot
				var a = path.split('.');
				for (var i = 0, n = a.length; i < n; ++i) {
					var k = a[i];
					if (k in object) {
						object = object[k];
					} else {
						return;
					}
				}
				return object;
			},
			/**
			 * Generate uuid
			 * @returns {string}
			 */
			generateUUID() {
				return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
					var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
					return v.toString(16);
				});
			}
		}
		Vue.prototype.$helpers = helpers;
	}
}