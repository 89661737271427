<template>
	<div>
		<form novalidate @submit.prevent="submitForm">

			<div class="md-layout-item box-errors" v-if="error">
				<span>
					{{ $t(`login.errors.${error}`) }}
				</span>
			</div>

			<div class="md-layout-item md-small-size-100">
				<md-field :class="getValidationClass('email')">
					<label for="email">
						{{ $t("login.form.email") }}
					</label>
					<md-input type="text"
							  id="email"
							  name="email"
							  autocomplete="email"
							  v-model="form.email"
							  :disabled="loading"/>
					<span class="md-error" v-if="!$v.form.email.required">
						{{ $t("login.errors.email-required") }}
					</span>
					<span class="md-error" v-else-if="!$v.form.email.email">
						{{ $t("login.errors.not-email") }}
					</span>
				</md-field>
			</div>

			<div class="md-layout-item md-small-size-100">
				<md-field :class="getValidationClass('password')">
					<label for="password">
						{{ $t("login.form.password") }}
					</label>
					<md-input type="password"
							  id="password"
							  name="password"
							  autocomplete="password"
							  v-model="form.password"
							  :disabled="loading"/>
					<span class="md-error" v-if="!$v.form.password.required">
						{{ $t("login.errors.password-required") }}
					</span>
				</md-field>
			</div>

			<md-card-actions>
				<md-button type="submit" class="md-primary" :disabled="loading">
					{{ $t("login.form.submit") }}
				</md-button>
			</md-card-actions>
		</form>
	</div>
</template>
<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {email} from 'vuelidate/lib/validators'
import firebase from "firebase";

export default {
	name: 'LoginForm',
	mixins: [validationMixin],
	data () {
		return {
			submitted: false,
			error: '',
			loading: false,
			form: {
				email: '',
				password: ''
			}
		};
	},
	validations: {
		form: {
			email: {
				required,
				email
			},
			password: {
				required
			}
		}
	},
	methods: {
		getValidationClass (fieldName) {
			if (this.$v) {
				const field = this.$v.form[fieldName]
				if (field) {
					return {
						'md-invalid': field.$invalid && field.$dirty
					}
				}
			}
		},
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true;
			this.error = '';
			this.loading = true;

			if (!this.$v.form.$invalid) {
				firebase
					.auth()
					.signInWithEmailAndPassword(this.form.email, this.form.password)
					.then(data => {
						data.user.getIdToken()
							.then((token) => {
								localStorage.setItem("token", token);
								this.$router.replace({ name: 'dashboard.page' });
								this.loading = false;
							})
						.catch(() => {
							this.loading = false;
						})
					})
					.catch(err => {
						this.error = err.code;
						this.loading = false;
					});
			} else {
				this.loading = false;
			}
		}
	}
}
</script>
<style lang="scss">
	@import "@/assets/scss/main";
</style>
