import firebase from "firebase";

export default {
	install: function (Vue) {
		const dbRef = firebase.database().ref();
		const rootRef = dbRef.child('journeys');

		const journeys = {
			/**
			 *
			 * @returns {{image: string, color: string, length: {it: string, base: string}, description: {it: string, base: string}, event_from: string, title: {it: string, base: string}, menu: [null, {image: string, color: string, subtitle: {it: string, base: string}, title: {it: string, base: string}, type: string, tags: [{tag: string, title: {it: string, base: string}, order: number}]}, {image: string, color: string, subtitle: {it: string, base: string}, title: {it: string, base: string}, type: string}], difficulty: {it: string, base: string}, duration: {it: string, base: string}, promote: number, places: [], event_to: string, subtitle: {it: string, base: string}, header: string, id: number, items: []}}
			 */
			getBaseObject () {
				return {
					color: "#247BA0",
					title: {
						base: "",
						it: ""
					},
					subtitle: {
						base: "",
						it: ""
					},
					description: {
						base: "",
						it: ""
					},
					length: {
						base: "",
						it: ""
					},
					difficulty: {
						base: "",
						it: ""
					},
					duration: {
						base: "",
						it: ""
					},
					places: [],
					items: [],
					event_from: "",
					event_to: "",
					header: "",
					id: 0,
					image: "",
					menu: [
						null,
						{
							color: "#0B577C",
							image: "https://firebasestorage.googleapis.com/v0/b/smart-agsm.appspot.com/o/journeys%2Fjourneys_things.png?alt=media&token=50d27206-c81e-4f8c-99a6-1ee9653dbcac",
							subtitle: {
								base: "Things to do",
								it: "Cose da fare"
							},
							tags: [{
								order: 1,
								tag: "monuments",
								title: {
									base: "Monuments",
									it: "Monumenti"
								}
							}],
							title: {
								base: "WHAT",
								it: "COSA"
							},
							type: "items"
						},
						{
							color: "#247BA0",
							image: "https://firebasestorage.googleapis.com/v0/b/smart-agsm.appspot.com/o/journeys%2Fjourney_locations.jpg?alt=media&token=73399b09-1898-4e72-bab0-3dbac2c3f9b6",
							subtitle: {
								base: "Map",
								it: "Mappa"
							},
							title: {
								base: "WHERE",
								it: "DOVE"
							},
							type: "locations"
						}
					],
					promote: 0
				}
			},
			/**
			 *
			 * @param id
			 * @returns {{item: {images: [string], x: number, description: {it: string, base: string}, y: number, html: {it: string, base: string}, date_to: string, id, title: {it: string, base: string}, place_id, date_from: string, order: number, tags: [string]}, place: {color: string, lon: null, id, radius: number, title: {it: string, base: string}, lat: null}}}
			 */
			getPointObject (id) {
				return {
					place: {
						"color": "#da2567",
						"id": id,
						"lat": null,
						"lon": null,
						"radius": 100,
						"title": {
							"base": "",
							"it": ""
						}
					},
					item: {
						"date_from": "",
						"date_to": "",
						"description": {
							"base": "",
							"it": ""
						},
						"html": {
							"base": "",
							"it": ""
						},
						"id": id,
						"images": [""],
						"image": "",
						"imageUploadTransfered": 0,
						"order": 0,
						"place_id": id,
						"tags": [
							"monuments"
						],
						"title": {
							"base": "---",
							"it": "---"
						},
						"x": -1,
						"y": -1
					}
				}
			},
			/**
			 *
			 * @returns {Promise<unknown>}
			 */
			getData () {
				return new Promise(resolve => {
					rootRef.get().then((snapshot) => {
						if (snapshot.exists()) {
							resolve(snapshot.val());
						} else {
							resolve([])
						}
					})
				})
			},
			setData (f) {
				return new Promise(resolve => {
					firebase.database().ref(`journeys/${f.id}`)
						.set(f, (error) => {
							if(error) {
								console.log(error);
								resolve(false);
							} else {
								resolve(true);
							}
						})
				})
			}
		}
		Vue.prototype.$journeys = journeys;
	}
}