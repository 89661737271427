import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './registerServiceWorker'
import i18n from './plugins/i18n.plugin'
import HelpersPlugin from './plugins/helpers.plugin'
import JourneysPlugin from './plugins/journeys.plugin'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use(HelpersPlugin)

import firebase from "firebase";
const configOptions = {
	apiKey: "AIzaSyB3OACRNl5wdnK2ALHIPqAQvHsupRsTV7M",
	authDomain: "smart-agsm.firebaseapp.com",
	databaseURL: "https://smart-agsm-prod.firebaseio.com/",
	projectId: "smart-agsm",
	storageBucket: "smart-agsm.appspot.com",
	messagingSenderId: "301923408724",
	appId: "1:301923408724:web:d957baa4e409352e2a86c1"
};
firebase.initializeApp(configOptions);
Vue.use(JourneysPlugin)

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
