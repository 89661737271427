<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-toolbar md-elevation="0" class="md-transparent">
				<div style="flex:1">
					<md-button @click="goBase()">
						{{ $t('common.back') }}
					</md-button>
				</div>
				<div>
					<md-button v-if="$route.params.id > 0" class="md-accent">
						{{ $t('common.delete') }}
					</md-button>
				</div>
			</md-toolbar>
		</div>

		<!-- form -->
		<div class="md-layout-item">
			<md-card v-if="loading">
				<md-card-content>
					<md-progress-bar md-mode="indeterminate"></md-progress-bar>
				</md-card-content>
			</md-card>

			<md-card class="md-layout-item md-size-100">
				<md-card-header>
					<div class="md-title">
						<span v-if="$route.params.id <= 0">{{ $t('journeys.new') }}</span>
					</div>
				</md-card-header>
				<md-card-content>
					<form novalidate @submit.prevent="submitForm" v-if="form">
						<!-- Errors -->
						<div class="md-layout-item box-errors" v-if="error">
							<span>
								{{ $t(`journeys.errors.${error}`) }}
							</span>
						</div>

						<!-- title -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('title.base')">
									<label for="title.base">
										{{ $t("journeys.form.title.base") }}
									</label>
									<md-input type="text"
											  id="title.base"
											  name="title.base"
											  autocomplete="title.base"
											  v-model="form.title.base"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.title.base.required">
										{{ $t("journeys.errors.title-base-required") }}
									</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('title.it')">
									<label for="title.it">
										{{ $t("journeys.form.title.it") }}
									</label>
									<md-input type="text"
											  id="title.it"
											  name="title.it"
											  autocomplete="title.it"
											  v-model="form.title.it"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.title.it.required">
										{{ $t("journeys.errors.title-it-required") }}
									</span>
								</md-field>
							</div>
						</div>

						<!-- description -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('description.base')">
									<label for="description.base">
										{{ $t("journeys.form.description.base") }}
									</label>
									<md-input type="text"
											  id="description.base"
											  name="description.base"
											  autocomplete="description.base"
											  v-model="form.description.base"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.description.base.required">
										{{ $t("journeys.errors.description-base-required") }}
									</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('description.it')">
									<label for="description.it">
										{{ $t("journeys.form.description.it") }}
									</label>
									<md-input type="text"
											  id="description.it"
											  name="description.it"
											  autocomplete="description.it"
											  v-model="form.description.it"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.description.it.required">
										{{ $t("journeys.errors.description-it-required") }}
									</span>
								</md-field>
							</div>
						</div>

						<!-- lenght -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('length.base')">
									<label for="length.base">
										{{ $t("journeys.form.length.base") }}
									</label>
									<md-input type="text"
											  id="length.base"
											  name="length.base"
											  autocomplete="length.base"
											  v-model="form.length.base"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.length.base.required">
										{{ $t("journeys.errors.length-base-required") }}
									</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('length.it')">
									<label for="length.it">
										{{ $t("journeys.form.length.it") }}
									</label>
									<md-input type="text"
											  id="length.it"
											  name="length.it"
											  autocomplete="length.it"
											  v-model="form.length.it"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.length.it.required">
										{{ $t("journeys.errors.length-it-required") }}
									</span>
								</md-field>
							</div>
						</div>

						<!-- difficulty -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('difficulty.base')">
									<label for="difficulty.base">
										{{ $t("journeys.form.difficulty.base") }}
									</label>
									<md-input type="text"
											  id="difficulty.base"
											  name="difficulty.base"
											  autocomplete="difficulty.base"
											  v-model="form.difficulty.base"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.difficulty.base.required">
										{{ $t("journeys.errors.difficulty-base-required") }}
									</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('difficulty.it')">
									<label for="difficulty.it">
										{{ $t("journeys.form.difficulty.it") }}
									</label>
									<md-input type="text"
											  id="difficulty.it"
											  name="difficulty.it"
											  autocomplete="difficulty.it"
											  v-model="form.difficulty.it"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.difficulty.it.required">
										{{ $t("journeys.errors.difficulty-it-required") }}
									</span>
								</md-field>
							</div>
						</div>

						<!-- duration -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('duration.base')">
									<label for="duration.base">
										{{ $t("journeys.form.duration.base") }}
									</label>
									<md-input type="text"
											  id="duration.base"
											  name="duration.base"
											  autocomplete="duration.base"
											  v-model="form.duration.base"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.duration.base.required">
										{{ $t("journeys.errors.duration-base-required") }}
									</span>
								</md-field>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('duration.it')">
									<label for="duration.it">
										{{ $t("journeys.form.duration.it") }}
									</label>
									<md-input type="text"
											  id="duration.it"
											  name="duration.it"
											  autocomplete="duration.it"
											  v-model="form.duration.it"
											  :disabled="loading"/>
									<span class="md-error" v-if="!$v.form.duration.it.required">
										{{ $t("journeys.errors.duration-it-required") }}
									</span>
								</md-field>
							</div>
						</div>

						<!-- file -->
						<div class="md-layout md-gutter">
							<div class="md-layout-item md-size-50 md-small-size-100">
								<md-field :class="getValidationClass('header')">
									<label>
										{{ $t("journeys.form.header.title") }}
									</label>
									<md-file :disabled="loading"
											 accept="image/*"
											 v-model="form.header"
											 @change="previewImage"
											 :placeholder="$t('journeys.form.header.select')"/>

									<span class="md-error" v-if="!$v.form.header.required">
										{{ $t("journeys.errors.header-required") }}
									</span>

								</md-field>
								<md-progress-bar v-if="uploadTransfered > 0 && uploadTransfered < 100"
												 md-mode="determinate" :md-value="uploadTransfered"></md-progress-bar>
							</div>
							<div class="md-layout-item md-size-50 md-small-size-100">
								<div class="md-error box-errors" v-if="uploadTransfered < 0">
									<span>
										{{ $t("journeys.errors.trans") }}
									</span>
								</div>
								<div v-if="form.header || uploadTransfered == 100">
									<img :src="form.header" style="max-height: 250px;">
								</div>
							</div>
						</div>

						<!-- punti di interesse-->
						<div class="md-layout-item">
							<md-toolbar md-elevation="0" class="md-transparent">
								<div style="flex:1">
									{{ $t('journeys.points.title') }}
								</div>
								<div>
									<md-button class="md-primary" @click="addPoint()">
										{{ $t('journeys.points.add_points') }}
									</md-button>
								</div>
							</md-toolbar>

							<div class="md-layout-item" v-for="(p, index) in form.places">
								<md-card class="md-layout-item card-poi">
									<md-card-header>
										<div class="md-subhead">
										</div>
									</md-card-header>
									<md-card-content>
										<!-- title -->
										<div class="md-layout md-gutter">
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('title.base', $v.form.places.$each[index])">
													<label>
														{{ $t("journeys.form.title.base") }}
													</label>
													<md-input type="text"
															  name="title.base"
															  autocomplete="p.title.base"
															  v-model="form.places[index].title.base"
															  :disabled="loading"/>
													<span class="md-error"
														  v-if="!$v.form.places.$each[index].title.base.required">
													{{ $t("journeys.errors.title-base-required") }}
												</span>
												</md-field>
											</div>
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('title.it', $v.form.places.$each[index])">
													<label>
														{{ $t("journeys.form.title.it") }}
													</label>
													<md-input type="text"
															  name="title.it"
															  autocomplete="p.title.it"
															  v-model="form.places[index].title.it"
															  :disabled="loading"/>
													<span class="md-error"
														  v-if="!$v.form.places.$each[index].title.it.required">
													{{ $t("journeys.errors.title-it-required") }}
												</span>
												</md-field>
											</div>
										</div>
										<!-- description -->
										<div class="md-layout md-gutter">
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('html.base', $v.form.items.$each[index])">
													<label>
														{{ $t("journeys.form.html.base") }}
													</label>
													<md-textarea type="text"
																 name="html.base"
																 autocomplete="p.html.base"
																 v-model="form.items[index].html.base"
																 :disabled="loading"
																 md-counter="200"/>
													<span class="md-error"
														  v-if="!$v.form.items.$each[index].html.base.required">
													{{ $t("journeys.errors.html-base-required") }}
												</span>
												</md-field>
											</div>
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('html.it', $v.form.items.$each[index])">
													<label>
														{{ $t("journeys.form.html.it") }}
													</label>
													<md-textarea type="text"
																 name="html.it"
																 autocomplete="p.html.it"
																 v-model="form.items[index].html.it"
																 :disabled="loading"
																 md-counter="200"/>
													<span class="md-error"
														  v-if="!$v.form.items.$each[index].html.it.required">
													{{ $t("journeys.errors.html-it-required") }}
												</span>
												</md-field>
											</div>
										</div>
										<!-- coordinate -->
										<div class="md-layout md-gutter">
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('lat', $v.form.places.$each[index])">
													<label>
														{{ $t("journeys.form.lat") }}
													</label>
													<md-input type="number"
															  name="lat"
															  autocomplete="p.lat"
															  v-model="form.places[index].lat"
															  :disabled="loading"/>
													<span class="md-error"
														  v-if="!$v.form.places.$each[index].lat.required">
													{{ $t("journeys.errors.lat-required") }}
												</span>
												</md-field>
											</div>
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('lon', $v.form.places.$each[index])">
													<label>
														{{ $t("journeys.form.lon") }}
													</label>
													<md-input type="number"
															  name="lon"
															  autocomplete="p.lon"
															  v-model="form.places[index].lon"
															  :disabled="loading"/>
													<span class="md-error"
														  v-if="!$v.form.places.$each[index].lon.required">
													{{ $t("journeys.errors.lon-required") }}
												</span>
												</md-field>
											</div>
										</div>
										<!-- file -->
										<div class="md-layout md-gutter">
											<div class="md-layout-item md-size-50 md-small-size-100">
												<md-field
													:class="getValidationClass('image', $v.form.items.$each[index])">
													<label>
														{{ $t("journeys.form.point_file.title") }}
													</label>
													<md-file :disabled="loading"
															 accept="image/*"
															 v-model="form.items[index].image"
															 @change="previewPointImage"
															 :data-index="index"
															 :placeholder="$t('journeys.form.point_file.select')"/>

													<!--<input type="file" id="file"
														   :data-index="index"
														   v-on:change="previewPointImage">-->

													<span class="md-error"
														  v-if="!$v.form.items.$each[index].image.required">
														{{ $t("journeys.errors.point_file-required") }}
													</span>
												</md-field>
												<md-progress-bar
													v-if="form.items[index].imageUploadTransfered > 0 && form.items[index].imageUploadTransfered < 100"
													md-mode="determinate"
													:md-value="form.items[index].imageUploadTransfered"></md-progress-bar>
											</div>
											<div class="md-layout-item md-size-50 md-small-size-100">
												<div class="md-error box-errors" v-if="uploadTransfered < 0">
													<span>
														{{ $t("journeys.errors.trans") }}
													</span>
												</div>
												<div v-if="form.items[index].images[0] || form.items[index].imageUploadTransfered == 100">
													<img :src="form.items[index].images[0]" style="max-height: 250px;">
												</div>
											</div>
										</div>
										<md-card-actions>
											<md-button type="submit" class="md-accent"
													   @click="deletePointModalIndex = index; deletePointModalActive = true"
													   :disabled="loading">
												{{ $t("common.delete") }}
											</md-button>
										</md-card-actions>
									</md-card-content>
								</md-card>
							</div>

							<md-toolbar md-elevation="0" class="md-transparent">
								<div style="flex:1">
									&nbsp;
								</div>
								<div>
									<md-button class="md-primary" @click="addPoint()">
										{{ $t('journeys.points.add_points') }}
									</md-button>
								</div>
							</md-toolbar>
						</div>

						<!-- actions -->
						<md-card-actions>
							<md-button type="submit" class="md-primary" :disabled="loading">
								{{ $t("journeys.form.submit") }}
							</md-button>
						</md-card-actions>
					</form>
				</md-card-content>
			</md-card>
		</div>
		<md-dialog-confirm
			:md-active.sync="deletePointModalActive"
			:md-title="$t('journeys.points.delete_point_title')"
			:md-content="$t('journeys.points.delete_point_title')"
			:md-confirm-text="$t('common.yes')"
			:md-cancel-text="$t('common.no')"
			@md-cancel="deletePointModalActive = false"
			@md-confirm="deletePoint">
		</md-dialog-confirm>
	</div>
</template>


<script>

import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import firebase from "firebase";
import {EventBus} from "@/events-bus";

export default {
	name: 'Journey',
	mixins: [validationMixin],
	data () {
		return {
			submitted: false,
			error: '',
			loading: false,
			form: null,
			uploadTransfered: null,
			deletePointModalActive: false,
			deletePointModalIndex: null
		};
	},
	validations: {
		form: {
			title: {
				base: {
					required
				},
				it: {
					required
				}
			},
			description: {
				base: {
					required
				},
				it: {
					required
				}
			},
			length: {
				base: {
					required
				},
				it: {
					required
				}
			},
			difficulty: {
				base: {
					required
				},
				it: {
					required
				}
			},
			duration: {
				base: {
					required
				},
				it: {
					required
				}
			},
			header: {
				required
			},
			places: {
				$each: {
					title: {
						base: {
							required
						},
						it: {
							required
						}
					},
					lat: {
						required
					},
					lon: {
						required
					}
				}
			},
			items: {
				$each: {
					html: {
						base: {
							required
						},
						it: {
							required
						}
					},
					image: {required}
				}
			}
		}
	},
	methods: {
		getValidationClass (fieldName, frm) {
			frm = frm || this.$v.form;
			if (this.$v) {
				const field = this.$helpers.getObjectByStringPath(frm, fieldName)
				if (field) {
					return {
						'md-invalid': field.$invalid && field.$dirty
					}
				}
			}
		},
		init () {
			if (+this.$route.params.id > 0) {
				// cagata, lo so
				this.loading = true;
				this.$journeys.getData()
					.then(data => {
						let d = data[+this.$route.params.id];
						d.items.map((i, index) => {
							d.items[index].image = d.items[index].images[0];
							d.items[index].imageUploadTransfered = 0;
						})
						this.form = d;
					})
					.finally(() => {
						this.loading = false;
					})
			} else {
				this.form = this.$journeys.getBaseObject();
			}
		},
		goBase () {
			this.$router.push({name: 'journeys.page'});
		},
		previewImage (event) {
			let image = event.target.files[0];
			let extension = image.type.replace("image/", "");
			let path = `/panel/journeys/${this.$helpers.generateUUID()}.${extension}`;
			const storageRef = firebase.storage().ref(path).put(image);

			this.uploadTransfered = 0;
			storageRef.on(`state_changed`, snapshot => {
					this.uploadTransfered = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				}, error => {
					console.log(error);
					this.form.header = "";
					this.form.image = "";
					this.uploadTransfered = -1;
				},
				() => {
					this.uploadTransfered = 100;
					storageRef.snapshot.ref.getDownloadURL().then((url) => {
						this.form.header = url;
						this.form.image = url;
					});
				}
			);
		},
		previewPointImage (event) {
			let image = event.target.files[0];

			if(image.type.includes("image/")) {
				const index = +event.target.dataset.index;
				let extension = image.type.replace("image/", "");
				let path = `/panel/journeys/point_${this.$helpers.generateUUID()}.${extension}`;
				const storageRef = firebase.storage().ref(path).put(image);

				this.form.items[index].imageUploadTransfered = 0;
				storageRef.on(`state_changed`, snapshot => {
						this.form.items[index].imageUploadTransfered = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					}, error => {
						this.form.items[index].images[0] = "";
						this.form.items[index].imageUploadTransfered = -1;
					},
					() => {
						this.form.items[index].imageUploadTransfered = 100;
						storageRef.snapshot.ref.getDownloadURL().then((url) => {
							this.form.items[index].images[0] = url;
							this.form.items[index].image = url;
							this.$forceUpdate();
						});
					}
				);
			} else {
				alert("L'immagine inserita non è un'immagine valida. Riprovare.")
			}
		},
		addPoint () {
			let id = (this.form.places.length == 0) ? 1 : null;
			if (id == null) {
				this.form.places.map(p => {
					id = (p.id >= id) ? p.id + 1 : id;
				})
			}
			let point = this.$journeys.getPointObject(id);
			this.form.places.push(point.place);
			this.form.items.push(point.item);
		},
		deletePoint () {
			this.form.places.splice(this.deletePointModalIndex, 1);
			this.form.items.splice(this.deletePointModalIndex, 1);
			this.deletePointModalIndex = null;
			this.deletePointModalActive = false;
		},
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true;
			this.error = '';
			this.loading = true;
			console.log(this.form.items[this.form.items.length - 1]);
			if (!this.$v.form.$invalid) {
				let f = {...this.form};

				// sistemo la form
				f.places.map((p, i) => {
					f.items[i].title.base = p.title.base;
					f.items[i].title.it = p.title.it;
				});

				// elimino gli elementi non necessari
				/*f.items.map((p, i) => {
					delete f.items[i].image;
					delete f.items[i].imageUploadTransfered;
				});*/

				this.$journeys.getData()
					.then(dataJourneys => {
						// prendo l'id
						let id = -1;
						if (this.$route.params.id <= 0) {
							// prendo l'id e lo salvo
							Object.keys(dataJourneys).map(k => {
								id = (k > id) ? k : id;
							});
							id = +id + 1;
						} else {
							id = this.$route.params.id;
						}
						// setto l'id
						f.id = id;

						this.$journeys.setData(f)
							.then(() => {
								EventBus.$emit('save-success');
								this.$router.push({name: 'journeys.page'});
							});
					})


			} else {
				console.log(this.$v.form.items)
				this.loading = false;
			}
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
</style>
