<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'App',
	methods: {
		init () {
			// init i18n materials
			this.$material.locale = this.$t('materials');
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
@import "@/assets/scss/main";
</style>
