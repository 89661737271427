<template>
	<div>
		<div class="md-layout md-layout-login md-gutter md-alignment-center-center">
			<div class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100">
				<md-toolbar class="md-primary" md-elevation="0">
					<h3 class="md-title">
						{{ $t('login.title') }}
					</h3>
				</md-toolbar>

				<md-card>
					<md-card-content class="">
						<LoginForm></LoginForm>
					</md-card-content>
				</md-card>
			</div>
		</div>
	</div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
export default {
	name: 'Login',
	components: {LoginForm},
	methods: {
		init () {
			localStorage.removeItem('token');
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
</style>