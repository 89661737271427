import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/pages/Login";
import MainLayout from "@/views/layouts/MainLayout";
import Dashboard from "@/views/pages/Dashboard";
import Journeys from "@/views/pages/Journeys";
import Journey from "@/views/pages/Journey";

Vue.use(VueRouter)

const guardAuthService = async (to, from, next) => {
	try {
		let token = localStorage.getItem('token')
		if (token && token !== '') {
			next()
		} else {
			next({
				name: 'login.page',
				// query: {redirectFrom: to.fullPath}
			})
		}
	} catch (e) {
		next({
			name: 'login.page',
			// query: {redirectFrom: to.fullPath}
		})
	}
};

const routes = [
	{
		path: '/login',
		name: 'login.page',
		component: Login,
	}, {
		path: '/',
		component: MainLayout,
		children: [{
			path: '/dashboard',
			name: 'dashboard.page',
			component: Dashboard,
			beforeEnter (to, from, next) {
				guardAuthService(to, from, next);
			}
		}, {
			path: '/journeys',
			name: 'journeys.page',
			component: Journeys,
			beforeEnter (to, from, next) {
				guardAuthService(to, from, next);
			}
		}, {
			path: '/journeys/:id',
			name: 'journey.page',
			component: Journey,
			beforeEnter (to, from, next) {
				guardAuthService(to, from, next);
			}
		}, {
			path: '*',
			redirect: '/dashboard'
		}]
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
