<template>
	<div class="md-layout">
		<md-toolbar md-elevation="0" class="md-transparent">
			<h1 class="md-title" style="flex: 1">{{ $t('journeys.title') }}</h1>
			<md-button class="md-primary" :to="{ name: 'journey.page', params: {id: 0} }">
				{{ $t('journeys.new') }}
			</md-button>
		</md-toolbar>
		<div class="md-layout-item">

			<md-card v-if="loading">
				<md-card-content>
					<md-progress-bar md-mode="indeterminate"></md-progress-bar>
				</md-card-content>
			</md-card>

			<md-table v-if="data && data.length" v-model="data" :md-sort.sync="currentSort"
					  :md-sort-order.sync="currentSortOrder" md-card>
				<md-table-row slot="md-table-row" slot-scope="{ item, index }" style="cursor: pointer" @click="go(item.key)">
					<md-table-cell :md-label="$t('journeys.table.image')">
						<md-avatar class="md-large">
							<img :src="item.header" alt="">
						</md-avatar>
					</md-table-cell>
					<!--<md-table-cell :md-label="$t('journeys.table.id')" md-numeric md-sort-by="id">
						{{ item.id }}
					</md-table-cell>-->
					<md-table-cell :md-label="$t('journeys.table.title')" md-sort-by="title.it">
						{{ item.title.it }}
					</md-table-cell>
					<md-table-cell :md-label="$t('journeys.table.description')" md-sort-by="description.it">
						{{ item.description.it }}
					</md-table-cell>
					<md-table-cell :md-label="$t('journeys.table.places')">
						{{ item.places.length }}
					</md-table-cell>
				</md-table-row>
			</md-table>
		</div>
	</div>


</template>


<script>
export default {
	name: 'Journeys',
	data () {
		return {
			loading: false,
			data: [],
			currentSort: 'title.it',
			currentSortOrder: 'asc'
		}
	},
	methods: {
		init () {
			this.loading = true;
			this.$journeys.getData()
				.then(data => {
					let keys = Object.keys(data);
					keys.map(k => {
						let t = {...data[k]};
						t.key = k;
						this.data.push(t);
					})
				})
				.finally(() => {
					this.loading = false;
				})
		},
		go (id) {
			this.$router.push({name: 'journey.page', params: {id: id}});
		}
	},
	mounted () {
		this.init();
	}
}
</script>

<style lang="scss">
</style>