<template>
	<div class="page-container md-layout-row">
		<md-app md-mode="reveal">
			<md-app-toolbar class="md-primary">
				<md-button v-if="showMenuIcon" class="md-icon-button" @click="menuVisible = !menuVisible">
					<md-icon>menu</md-icon>
				</md-button>
				<span class="md-title">AGSM Panel</span>

				<div class="md-toolbar-section-end">
					<md-menu>
						<md-button class="md-icon-button" md-menu-trigger>
							<md-icon>person</md-icon>
						</md-button>

						<md-menu-content>
							<!--<md-divider></md-divider>

							<md-menu-item :to="{ name: 'login.page' }">
								<md-icon>face</md-icon>
								<span>{{ $t('common.profile') }}</span>
							</md-menu-item>-->

							<md-menu-item :to="{ name: 'login.page' }">
								<md-icon>logout</md-icon>
								<span>{{ $t('common.logout') }}</span>
							</md-menu-item>
						</md-menu-content>
					</md-menu>
					<!--<md-button class="md-icon-button">
						<md-icon>more_vert</md-icon>
					</md-button>-->
				</div>
			</md-app-toolbar>

			<md-app-drawer :md-active.sync="menuVisible" md-permanent="clipped">
				<md-list>
					<md-list-item :to="{ name: 'dashboard.page' }">
						<md-icon>dashboard</md-icon>
						<span class="md-list-item-text">{{ $t('menu.dashboard') }}</span>
					</md-list-item>
					<md-list-item :to="{ name: 'journeys.page' }">
						<md-icon>map</md-icon>
						<span class="md-list-item-text">{{ $t('menu.journeys') }}</span>
					</md-list-item>
				</md-list>
			</md-app-drawer>
			<md-app-content class="app-content">
				<router-view/>

				<md-snackbar md-position="left" :md-duration="2000" :md-active.sync="showSaveSuccess" md-persistent>
					<span>
						{{ $t('common.save_success') }}
					</span>
					<md-button class="md-primary" @click="showSaveSuccess = false">
						{{ $t('common.close') }}
					</md-button>
				</md-snackbar>
			</md-app-content>
		</md-app>
	</div>
</template>

<script>
import {EventBus} from "@/events-bus";

export default {
	name: 'MainLayout',
	data () {
		return {
			menuVisible: false,
			showMenuIcon: true,
			showSaveSuccess: false
		}
	},
	methods: {
		onResize () {
			if (this.$helpers.isMobile()) {
				this.menuVisible = false;
				this.showMenuIcon = true;
			} else {
				this.menuVisible = true;
				this.showMenuIcon = false;
			}
		}
	},
	mounted () {
		this.onResize();
		window.addEventListener('resize', this.onResize, {passive: true})

		// Events
		EventBus.$on('save-success', () => {
			this.showSaveSuccess = true;
		})

	},
	beforeDestroy () {
		if (typeof window !== 'undefined') {
			window.removeEventListener('resize', this.onResize, {passive: true})
		}
	}
}
</script>

<style lang="scss" scoped>
.md-app {
	height: 100vh;
}

.md-drawer {
	width: 250px;
	max-width: calc(100vw - 125px);
}
</style>